import { RouteLocationNormalized } from 'vue-router'
import { AuthenticationContext, IAMPredicates } from '@ankor-io/common/auth/client/types'
import { getJwtPayload, JwtPayload } from '@ankor-io/common/auth/jwt'
import { AuthenticationClientOptions } from '@ankor-io/commodore/src/iam/types'

/**
 * Create the Router Guards to use in the router.
 *
 * @param authenticationContext the authentication context to check authentication against.
 * @param authenticationClientOptions the authentication client options.
 * @returns an IAMPredicates of methods aligning to the corresponding router guards
 */
export const iamPredicates = (authenticationContext: AuthenticationContext,
                              authenticationClientOptions: AuthenticationClientOptions): IAMPredicates => {
  //
  const beforeEach = async (to: RouteLocationNormalized) => {
    // see: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards

    // check this route was marked as skip authentication.
    if (to.matched.some((record) => record.meta.skipAuthentication)) {
      console.debug('>>> authentication check skipped.')
      return true
    }
    // is this a request to be redirected to the registration
    if (to.matched.some((record) => record.meta.toRegistration)) {
      console.debug('>>> redirect to registration page.')
      await authenticationContext.redirectToRegister?.()
      return false
    }

    //
    let token
    try {
      token = await authenticationContext.getToken()

      if (token === undefined) {
        throw new Error('undefined token response')
      }
    } catch (e: any) {
      // log it.
      console.error(`The call to get the token has failed, redirecting to login: ${e}`)
      // as some error occured (typically: the token is expired and the lib upchucks)
      // we should redirect back to authenticate!
      await authenticationContext.redirectToLogin()
      return false
    }

    // verify if the token has valid authorization
    // with permission to access commodore and is part of the organization
    const jwtPayload: JwtPayload = getJwtPayload(token)

    if (jwtPayload.org_code !== authenticationClientOptions.org_code) {
      /**
       * The user is not part of the organization, logout the user
       */
      await authenticationContext.logout()
      return false
    }
    // auth ok -- continue with the route.
    return true
  }

  //
  return {
    beforeEach,
  }
}
